<template>
  <section class="mt-4">
    <b-tabs>
      <b-tab-item label="Таблица">
        <b-field label="Поиск">
          <b-input placeholder="Поиск"
              type="search"
              icon="search"
              v-model="filters.search_value"
              @input="search"
              icon-clickable>
          </b-input>
        </b-field>
        <b-pagination
          :total="total"
          :current="currentPage"
          :simple="false"
          :per-page="filters.limit"
          :paginated="false"
          :pagination-simple="true"
          order="is-right"
          @change="pageChange">
        </b-pagination>
        <div class="table-container">
          <b-table
            :data="data"
            :bordered="true"
            :narrowed="true"
            :hoverable="true"
            default-sort-direction="desc"
            default-sort="date">
            <!-- <b-table-column field="id" label="ID" width="40" numeric centered v-slot="props">
              <router-link :to="{name: 'a-dish', params: {id: props.row.db_id}}">
                {{ props.row.db_id }}
              </router-link>
              </b-table-column> -->
            <b-table-column field="id" label="ID" width="40" numeric centered v-slot="props">
              {{ props.row.id }}
            </b-table-column>
            <b-table-column field="code" label="CODE" width="40" numeric centered v-slot="props">
              {{ props.row.code }}
            </b-table-column>
            <b-table-column field="guid" label="GUID" numeric centered v-slot="props">
              {{ props.row.guid }}
            </b-table-column>
            <!-- <b-table-column field="id" label="Группа" centered v-slot="props">
              <template v-if="props.row.group_id">
                <router-link :to="{name: 'a-group-dish', params: {id: props.row.group_id}}">
                  {{ props.row.group.id }}
                </router-link>
              </template>
              </b-table-column> -->
            <b-table-column field="name" label="Наименование" centered v-slot="props">
              {{ props.row.name }}
            </b-table-column>
            <!--           <b-table-column field="type" label="Тип подачи" centered v-slot="props">
              {{ props.row.mvtp_Precision }} {{ props.row.mvtp_Name }}
              </b-table-column> -->
            <b-table-column field="price" label="Цена" centered v-slot="props">
              {{ props.row.price/100 }} Р.
            </b-table-column>
            <b-table-column field="modifiers_id" label="Схема модификатора" centered v-slot="props">
              <template v-if="props.row.modifiers_id">
                <router-link :to="{name: 'a-modifier', params: {id: props.row.modifiers.id}}">
                  {{ props.row.modischeme }}
                </router-link>
              </template>
            </b-table-column>
            <b-table-column field="action" label="Действие" centered v-slot="props">
              <b-tooltip label="Просмотр">
                <router-link :to="{name: 'a-dish', params: {id: props.row.db_id }}" class="navbar-item">
                  <font-awesome-icon icon="eye" />
                </router-link>
              </b-tooltip>
            </b-table-column>
          </b-table>
        </div>
      </b-tab-item>
      <b-tab-item label="JSON">
        <div class="app-json">
          <vue-json-pretty
            :path="'res'"
            :data="data">
          </vue-json-pretty>
        </div>
      </b-tab-item>
    </b-tabs>
  </section>
</template>
<script>
  import debounce from 'lodash/debounce'
  import { EventBus, limits } from '@/utils'
  import { getDishes, getPageDishes } from '@/api/dish'
  // import { ToastProgrammatic as Toast } from 'buefy'
  
  import VueJsonPretty from 'vue-json-pretty'
  import 'vue-json-pretty/lib/styles.css'
  
  export default {
    name: "DishTable",
    components: {
      VueJsonPretty
    },
    data() {
      const data = []
  
      return {
        data: data,
        limits: limits,
        currentPage: 1,
        total: 0,
        filters: {
          order: 'asc',
          limit: 25,
          search_value: null
        },
        is_admin: false
      }
    },
    computed: {
      //
    },
    mounted: function() {
      let $this = this
  
      getDishes(this.$store.state.jwt_user)
        .then(response => {
          let data = response.data;
          $this.total = data.total
  
          data.data.forEach((item) => {
            this.data.push(item)
          })
        })
        .catch(
          this.data = []
        )
  
      // EventBus.$on('error', (error) => {
      //   $this.pageChange()
      //   this.$toast.open({
      //     // message: `${this.$i18n.t('form.error')} ${error}`,
      //     message: 'error',
      //     type: 'is-danger',
      //     position: 'is-bottom'
      //   })
      // })
  
      // EventBus.$on('fail', (error) => {
      //   $this.pageChange()
      //   this.$toast.open({
      //     // message: `${this.$i18n.t('form.fail')} ${error}`,
      //     message: 'fail',
      //     type: 'is-danger',
      //     position: 'is-bottom'
      //   })
      // })
  
    },
    beforeDestroy: function(){
      EventBus.$off('error')
      EventBus.$off('fail')
    },
    methods: {
      pageChange(page){
        if(page){
          this.currentPage = page
        }
        getPageDishes(this.currentPage, this.filters, this.$store.state.jwt_user)
          .then(response => {
            let data = response.data;
            
            this.data = []
            this.total = data.total
            data.data.forEach((item) => {
              this.data.push(item)
            })
  
          })
          .catch(
            this.data = []
          )
      },
      search: debounce(function() {

        if (this.filters.search_value == null || this.filters.search_value == ''){
          this.pageChange()
          return
        }

        getPageDishes(1, this.filters, this.$store.state.jwt_user)
          .then(response => {
            let data = response.data;
            
            this.data = []
            this.total = data.total
            data.data.forEach((item) => {
              this.data.push(item)
            })

          })
          .catch(() => {
              this.data = []
            }
          )
      }, 500),
    }
  }
</script>
<style></style>